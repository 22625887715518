<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'Listado de etiquetas' }]"
    />
    <v-card style="border-radius:10px 10px 0 0;">
      <v-toolbar card style="border-radius:10px 10px 0 0;" color="primary" dark>
        <v-toolbar-title>{{ this.currentProducto.nombre }} ({{ this.currentProducto.categoria.nombre }})</v-toolbar-title>
         <v-spacer />
        <v-btn large :to="{ name: 'ListadoProductItems', params: { id: $route.params.id } }" color="success">
         <svg style="width:24px;height:24px;margin-right: 10px;" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z" />
                  </svg>
          Ver Items
        </v-btn>
      </v-toolbar>
      <v-bottom-nav :active.sync="activeBtn" color="transparent" :value="true">
        <v-btn :to="{ name: 'EditProduct', params: { id: $route.params.id } }" flat>
          <span>Informacion</span>
          <v-icon>assignment</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListaProductAtributo', params: { id: $route.params.id } }" flat>
          <span>Atributos</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListaProductImagen', params: { id: $route.params.id } }" flat>
          <span>Imagenes</span>
          <v-icon>image</v-icon>
        </v-btn>
        <v-btn :to="{ name: '', params: { id: $route.params.id } }" flat color="teal">
          <span>Etiquetas</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListRating', params: { id: $route.params.id } }" flat color="grey darken-1">
          <span>Ratings</span>
          <v-icon>star</v-icon>
        </v-btn>
      </v-bottom-nav>
      <br />
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Listado de Etiquetas</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'AgregarEtiqueta', params: { id: $route.params.id } }" color="primary">
          <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Etiqueta
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-data-table
          :headers="[
            { text: 'Etiqueta', value: 'etiqueta.nombre' },
            { text: 'Categoría', value: 'etiqueta.titulo' },
            { text: 'Fecha inicio', value: 'fecha_inicio' },
            { text: 'Fecha termino', value: 'fecha_termino' },
            { text: 'Acciones' }
          ]"
          :items="etiquetas"
          :loading="loadingEtiquetas"
          :rows-per-page-items="[10, 25, 35, 50]"
          class="elevation-1"
        >
          <tr slot="items" slot-scope="props">
            <td class="px-3">
              {{ props.item.etiqueta.nombre }}
            </td>
            <td class="px-3">
              {{ props.item.etiqueta.titulo }}
            </td>
            <td class="px-3">
              {{ formatDate(props.item.fecha_inicio) }}
            </td>
            <td class="px-3">
              {{ formatDate(props.item.fecha_termino) }}
            </td>
            <td class="px-3">
              <v-btn
                class="ma-0"
                :to="{ name: 'EditProductoEtiqueta', params: { id: props.item.id } }"
                small
                icon
                flat
                color="info"
              >
                <v-icon small>
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                v-if="userIsAdmin || userIsSupervisor"
                class="ma-0"
                small
                icon
                flat
                @click="openModalDeleteEtiqueta(props.item)"
                color="error"
              >
                <v-icon small>
                  delete
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </v-data-table>
      </v-container>
      <center>
        <v-btn color="error" @click="$router.push({ name: 'ListaProducto' })">
          Volver
        </v-btn>
      </center>
    </v-card>
    <ModalDeleteEtiqueta />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de imagenes" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDeleteEtiqueta: () => import("@/views/productos/ModalDeleteEtiqueta")
  },
  data() {
    return {
      activeBtn: 4
    };
  },

  computed: {
    ...mapState({
      etiquetas: state => state.productos.etiquetas,
      loadingEtiquetas: state => state.productos.loadingEtiquetas,
      currentProducto: state => state.productos.currentProducto
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
      userIsAdmin: "auth/userIsAdmin",
      userIsSupervisor: "auth/userIsSupervisor"
    })
  },
  created() {
    this.getEtiquetas({ productoId: this.$route.params.id });
    this.getProducto({ productoId: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      getEtiquetas: "productos/getEtiquetas",
      getProducto: "productos/getProducto",
      replaceShowModalDeleteEtiqueta: "productos/replaceShowModalDeleteEtiqueta",
      replaceCurrentEtiqueta: "productos/replaceCurrentEtiqueta"
    }),
    openModalDeleteEtiqueta(etiqueta) {
      this.replaceCurrentEtiqueta({ etiqueta });
      this.replaceShowModalDeleteEtiqueta({ status: true });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day, hour] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  }
};
</script>
